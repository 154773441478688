import Cookies from "js-cookie";
import ReactGA from "react-ga4";
import { setCookie } from "../utils";
import {
  post,
  get,
  patch,
  del,
  patchWithResponse,
  postWithResponse,
  postWithFormData,
} from "./api";

export default class backendAPI {
  constructor() {
    ReactGA.initialize(process.env.VITE_REACT_APP_GA_ID);
  }

  async register(formData) {
    const res = await postWithResponse("/auth/register", formData);
    ReactGA.event({
      category: "Registration",
      action: "registration_active",
      label: formData.email,
    });

    return res;
  }

  async submitUserApplication(payload) {
    const res = await postWithResponse("/auth/user-application", payload);
    return res;
  }

  async forgotPassword(email) {
    return await post("/forgot-password", email, null, {
      headers: { "Content-Type": "application/text" },
    });
  }

  async resetPassword(newPassword, token) {
    return await post("/auth/reset-password", { newPassword, token });
  }

  async changePasswordWithOldOne(newPassword, oldPassword) {
    return await post("/auth/change-password", {
      newPassword,
      oldPassword,
    });
  }

  async getProfile() {
    return await get("/auth/getUserProfile");
  }

  async getInvoiceSettings() {
    return await get("/auth/get-invoice-settings");
  }

  async updateInvoiceSettings(settings) {
    return await patch("/auth/update-invoice-settings", settings);
  }

  async update(formData) {
    return await patchWithResponse("/auth/update", formData);
  }

  async updateAddress(formData) {
    return await postWithResponse("/auth/update-address", formData);
  }

  async setEnablePhishingCode(enable) {
    return await patch("/auth/update/enable-anti-phishing-code", enable);
  }

  async setPhishingCode(body) {
    return await post("/auth/setup/antiPhishingCode", body);
  }

  async updateFirstName(firstName) {
    const callback = (response) => {
      localStorage.setItem("firstName", firstName);
    };
    return await patch("/auth/update/first-name", { firstName }, callback);
  }

  async updateLastName(lastName) {
    const callback = (response) => {
      localStorage.setItem("lastName", lastName);
    };
    return await patch("/auth/update/last-name", { lastName }, callback);
  }

  async getTotpToken() {
    return await post("/auth/setup/getTotpToken", {});
  }

  async verifyTotpToken(email, code, checkbox) {
    const callback = (response) => {
      setCookie("token", response.data.jwtToken);
      setCookie("refreshtoken", response.data.refreshToken);

      ReactGA.event({
        category: "User",
        action: "login",
        label: response.data.email,
      });
    };

    return await post(
      "/auth/verify/totp",
      {
        email: email,
        token: code,
        rememberMe: checkbox,
      },
      callback,
    );
  }

  async setupTotp(data) {
    return await post("/auth/setup/totp", data);
  }

  async setupOtp(data) {
    return await post("/auth/setup/otp", data);
  }

  async uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    return await post("/auth/upload", formData, null, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  async signout() {
    const callback = (response) => {
      localStorage.clear();
      Cookies.remove("token");
      Cookies.remove("refreshtoken");
    };

    return await post("/auth/signout", {}, callback);
  }

  async login(username, password) {
    const callback = (response) => {
      if (!response.data.hasOtp) {
        setCookie("token", response.data.jwtToken);
        setCookie("refreshtoken", response.data.refreshToken);
      }
      ReactGA.event({
        category: "User",
        action: "login",
        label: response.data.email,
      });
    };
    return await post("/auth/login", { email: username, password }, callback);
  }

  async verifyOTP(email, code, longToken) {
    const callback = (response) => {
      const step = response.data.hasOtp !== response.data.hasTotp;
      if (step) {
        setCookie("token", response.data.jwtToken);
        setCookie("refreshtoken", response.data.refreshToken);

        ReactGA.event({
          category: "User",
          action: "login",
          label: response.data.email,
        });
      }
    };

    return await post(
      "/auth/verify/otp",
      {
        email: email,
        code,
        rememberMe: longToken,
      },
      callback,
    );
  }

  async activateAccount(token) {
    return await patch("/auth/activate", token);
  }

  async checkPassword(password) {
    return await post("/auth/checkPassword", { password });
  }

  async uploadKYCByType(type, file) {
    if (!file) {
      return null;
    }

    const formData = new FormData();
    formData.append("file", file);

    return await post("/auth/upload_kyc?type=" + type, formData, null, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  async uploadKYCByText(type, content) {
    if (!content) {
      return null;
    }
    const formData = new FormData();
    formData.append("content", content);

    return await postWithFormData(
      "/auth/upload_kyc_text?type=" + type,
      formData,
    );
  }

  async getByKYC(type) {
    return {
      [type]: await get("/auth/kyc-image-url?type=" + type),
    };
  }

  async getByKYCText(type) {
    return {
      [type]: await get(`/auth/kyc-text-url?type=${type}`),
    };
  }

  async getKYCLevel() {
    return await get("/auth/kyc-level");
  }

  async deleteProfileImage() {
    return await post("/auth/deleteImage", {});
  }

  async addUserPassword(payload) {
    return postWithResponse("/auth/password", payload);
  }

  async getWalletAddresses() {
    return await get("/wallet/addresses");
  }

  async deleteWallet(address) {
    return await del("/wallet/delete-wallet?address=" + address, {});
  }

  async send(
    tokenAddress,
    blockchain,
    amount,
    fromAddress,
    toAddress,
    password,
  ) {
    return await post("/wallet/send", {
      tokenAddress,
      blockchain,
      amount,
      fromAddress,
      toAddress,
      password,
    });
  }

  async getProduct(productLink) {
    return await get("/product/" + productLink);
  }

  async getProductImage(productLink) {
    return await get("/productImage/" + productLink);
  }

  async getHierarchy(email) {
    return await get("/hierarchy/" + email);
  }

  async getFees(email) {
    return await get("/fees/" + email);
  }

  async setTransactionInfo(
    transactionInfo,
    buyerAddress,
    productOrInvoiceId,
    invoiceInfo,
  ) {
    return await post("/transaction", {
      transactionInfo,
      buyerAddress,
      ...productOrInvoiceId,
      invoiceInfo,
    });
  }

  async getInvoice(invoiceLink) {
    return await get("/invoice/" + invoiceLink);
  }

  async updateInvoice(link, requestBody) {
    return await post("/invoice/" + link, requestBody);
  }

  async makePayment(
    currencyAddress,
    blockchain,
    amount,
    quantity,
    password,
    stablecoinAddress,
    transInfoArg,
    invoiceInfo,
  ) {
    const callback = (response) => {
      if (response.status === 412) {
        return "insufficientFunds";
      }
    };
    return await post(
      "/payment",
      {
        currencyAddress,
        stablecoinAddress,
        amount,
        quantity,
        password,
        blockchain,
        ...transInfoArg,
        invoiceInfo,
      },
      callback,
    );
  }

  async registerWalletAddress(address, type) {
    return await get("/wallet/address?address=" + address + "&type=" + type);
  }

  async getSeedPhrase(password) {
    return await post("/wallet/seedPhrase", { password });
  }

  async recoverWallet(seedPhrase, newPassword) {
    return await post("/wallet/recoverWallet", {
      token: seedPhrase,
      newPassword,
    });
  }

  async handleSeed() {
    return await post(`/wallet/seedVerified`);
  }

  async getCurrencyRate(from, to) {
    return await get("/currency?from=" + from + "&to=" + to);
  }

  async getRateList(base) {
    return await get("/currencyList?base=" + base);
  }
  async getTaxInfo(country) {
    return await get("/taxInfo/" + country);
  }
  async checkIfAgent() {
    return await get("/auth/agent");
  }
  async swap(body) {
    return await post("/auth/swap", body);
  }
  async httpRequest(url, method, body, headers) {
    try {
      let fetchUrl = url;
      const option = {
        method,
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
      };
      if (method.toLowerCase() === "get") {
        const searchParams = Object.keys(body)
          .map((key) => key + "=" + body[key])
          .join("&");
        if (searchParams) {
          fetchUrl += "?" + searchParams;
        }
      } else {
        option.body = JSON.stringify(body);
      }
      const response = await fetch(fetchUrl, option);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return null;
    }
  }

  async resendConfirmationEmail(email) {
    try {
      return await get(`/auth/resend-email?email=${email}`);
    } catch (error) {
      console.error("There was an error activating the account:", error);
      return error; // or return some default value
    }
  }

  async generateApiKeys(payload) {
    return postWithResponse("/auth/generate-keys", payload);
  }

  async getApiKeys() {
    return await get("/auth/api-keys");
  }

  async deleteApiKeys(id) {
    return await del(`/auth/delete/api-keys/${id}`, {});
  }
}
