// ThemeContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import { ConfigProvider, theme as AntDTheme } from "antd";
import { useLocation } from "react-router-dom";
import { getThemeCookie, setCookie } from "../../utils";

const ThemeContext = createContext();

const defaultDarkModeRoutes = [
  "/",
  "/b2b",
  "/b2c",
  "/vacancy",
  " /technical-support",
  "/business-support",
];

export const ThemeProvider = ({ children }) => {
  const location = useLocation();
  const [theme, setTheme] = useState(null);
  const { defaultAlgorithm, darkAlgorithm } = AntDTheme;
  const toggleTheme = () => {
    setCookie("theme", theme === "light" ? "dark" : "light");
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  useEffect(() => {
    defaultDarkModeRoutes.some((route) => route === location.pathname)
      ? setTheme("dark")
      : setTheme(getThemeCookie() ?? "dark");
  }, [location]);

  useEffect(() => {
    document.body.classList.remove("light", "dark");
    document.body.classList.add(`${theme}`);
  }, [theme]);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme == "dark" ? darkAlgorithm : defaultAlgorithm,
      }}
    >
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        {children}
      </ThemeContext.Provider>
    </ConfigProvider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
