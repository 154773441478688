import axios from "axios";
import Cookies from "js-cookie";

const instance = axios.create({
  baseURL: process.env.VITE_REACT_APP_BASE_ENDPOINT_API,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/auth/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/auth/refreshtoken", {
            refreshToken: Cookies.get("refreshtoken"),
          });

          const { jwtToken } = rs.data;
          Cookies.set("token", jwtToken);

          return instance(originalConfig);
        } catch (_error) {
          clear();
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  },
);

export default instance;

export function clear() {
  const route = window.location.href?.split("?")[0];
  if (
    route == window.location.origin + "/login" ||
    route == window.location.origin + "/get-started" ||
    route == window.location.origin + "/install-app" ||
    route == window.location.origin + "/forgot-password" ||
    route == window.location.origin + "/reset-password" ||
    route.startsWith(window.location.origin + "/pay") ||
    route.startsWith(window.location.origin + "/product")
  )
    return;
  localStorage.clear();
  Cookies.remove("token");
  Cookies.remove("refreshtoken");
  window.location.href = "/login";
}

export async function post(url, requestBody, callback, config) {
  return instance
    .post(url, requestBody, config)
    .then((response) => {
      if (callback) {
        callback(response);
      }
      return response.data;
    })
    .catch((error) => {
      return null;
    });
}

export async function postWithFormData(url, requestBody, callback) {
  return instance
    .post(url, requestBody, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
      if (callback) {
        callback(response);
      }
      return response.data;
    })
    .catch((error) => {
      return null;
    });
}

export async function postWithResponse(url, requestBody) {
  return instance
    .post(url, requestBody)
    .then((response) => response)
    .catch((error) => error.response);
}

export async function get(url, callback, config) {
  return instance
    .get(url, config)
    .then((response) => {
      if (callback) {
        callback(response);
      }
      return response.data;
    })
    .catch((error) => {
      return null;
    });
}

export async function patch(url, requestBody, callback) {
  return instance
    .patch(url, requestBody)
    .then((response) => {
      if (callback) {
        callback(response);
      }
      return response.data;
    })
    .catch((error) => {
      return null;
    });
}

export async function patchWithResponse(url, requestBody) {
  return instance
    .patch(url, requestBody)
    .then((response) => response)
    .catch((error) => error.response);
}

export async function del(url, requestBody, callback) {
  return instance
    .delete(url, { data: requestBody })
    .then((response) => {
      if (callback) {
        callback(response);
      }
      return response.data;
    })
    .catch((error) => {
      return null;
    });
}

export async function put(url, requestBody, callback) {
  return api
    .put(url, requestBody)
    .then((response) => {
      if (callback) {
        callback(response);
      }
      return response.data;
    })
    .catch((error) => {
      return null;
    });
}

export async function putWithResponse(url, requestBody) {
  return instance
    .put(url, requestBody)
    .then((response) => response)
    .catch((error) => error.response);
}
