import { createContext, useState } from "react";
import { toast } from "react-toastify";
import { useTheme } from "../themeContext/themeContext";
import SuccessToastIcon from "../../assets/newDashboardIcons/success-toast-icon.png";
import InfoToastIcon from "../../assets/newDashboardIcons/info-toast-icon.png";
import ErrorToastIcon from "../../assets/newDashboardIcons/error-toast-icon.png";
import { Flex } from "antd";
import "./message.css";
import { useTranslation } from "react-i18next";

export const MessageContext = createContext({
  infoMessage: undefined,
  errorMessage: undefined,
  setInfoMessage: () => {
    alert("Init");
  },
  setErrorMessage: () => {},
});

export function MessageContextProvider({ children }) {
  const { theme } = useTheme();
  const { t, i18n } = useTranslation();

  const [infoMessage, setInfoMessage] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(undefined);
  function setSuccessMessageClear(message, duration = 5000, language = "") {
    toast.success(
      <Flex gap={12} align="start">
        <div>
          <img
            src={SuccessToastIcon}
            alt="success-icon"
            width={28}
            height={28}
          />
        </div>
        <Flex gap={2} vertical>
          <div className="default-text toast-title">
            {t("messages.success.title", { lng: language || i18n.language })}
          </div>
          <div className="default-text-gray toast-subtext">{message}</div>
        </Flex>
      </Flex>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: duration,
        theme: theme,
        progress: undefined,
        icon: false,
      },
    );
  }

  function setInfoMessageClear(message, duration = 5000, language = "") {
    // setErrorMessage(undefined);
    // setInfoMessage(message);
    toast.info(
      <Flex gap={12} align="start">
        <div>
          <img src={InfoToastIcon} alt="success-icon" width={28} height={28} />
        </div>
        <Flex gap={2} vertical>
          <div className="default-text toast-title">
            {t("messages.info.title", { lng: language || i18n.language })}
          </div>
          <div className="default-text-gray toast-subtext">{message}</div>
        </Flex>
      </Flex>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: duration,
        theme: theme,
        progress: undefined,
        icon: false,
      },
    );
  }

  function setErrorMessageClear(message, duration = 5000, language = "") {
    // setInfoMessage(undefined);
    // setErrorMessage(message);
    toast.error(
      <Flex gap={12} align="start">
        <div>
          <img src={ErrorToastIcon} alt="success-icon" width={28} height={28} />
        </div>
        <Flex gap={2} vertical>
          <div className="default-text toast-title">
            {t("messages.error.title", { lng: language || i18n.language })}
          </div>
          <div className="default-text-gray toast-subtext">{message}</div>
        </Flex>
      </Flex>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: duration,
        theme: theme,
        progress: undefined,
        icon: false,
      },
    );
  }

  function clearMessages() {
    setInfoMessage(undefined);
    setErrorMessage(undefined);
    setSuccessMessage(undefined);
  }

  return (
    <MessageContext.Provider
      value={{
        infoMessage: infoMessage,
        errorMessage: errorMessage,
        successMessage: successMessage,
        setSuccessMessage: setSuccessMessageClear,
        setInfoMessage: setInfoMessageClear,
        setErrorMessage: setErrorMessageClear,
        clearMessages: clearMessages,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
}
