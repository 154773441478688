import React, { Suspense } from "react";
import LoadingAnimation from "../assets/logo/loadingAnimation.json";
import { Player } from "@lottiefiles/react-lottie-player";

const SuspenseWrapper = ({ children }) => {
  const loadingFallback = (
    <div className="loadingAnimationWrapper">
      <Player src={LoadingAnimation} className="loadingAnimation" autoplay />
    </div>
  );

  return <Suspense fallback={loadingFallback}>{children}</Suspense>;
};

export default SuspenseWrapper;
