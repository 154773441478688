import { Link } from "react-router-dom";

import styles from "./cookie.module.css";
import { useTranslation } from "react-i18next";
import { acceptCookie, declineCookie } from "../../utils";
import { useTheme } from "../../context/themeContext/themeContext";

const CookieBanner = ({ close }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const accept = () => {
    acceptCookie();
    close();
  };

  const decline = () => {
    declineCookie();
    close();
  };

  return (
    <div className={`${styles.banner} card`}>
      <div className={styles.left}>
        <svg
          width="50"
          height="50"
          viewBox="0 0 50 50"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M48.995 22.5491C48.3875 22.0891 47.6 21.9316 46.86 22.1191C46.145 22.3041 45.555 22.3891 45 22.3891C40.865 22.3891 37.5 19.0241 37.4925 15.0441C37.505 14.9591 37.5325 14.7091 37.535 14.6241C37.5675 13.8316 37.22 13.0716 36.6025 12.5766C35.985 12.0816 35.1675 11.9016 34.4 12.1091C33.6675 12.2991 33.065 12.3891 32.5 12.3891C28.365 12.3891 25 9.02411 25 4.88911C25 4.34661 25.0775 3.77911 25.2475 3.09911C25.4425 2.31161 25.2425 1.47911 24.7125 0.864106C24.185 0.251606 23.41 -0.0583935 22.58 0.00910648C9.7075 1.24911 0 11.9441 0 24.8891C0 38.6741 11.215 49.8891 25 49.8891C38.785 49.8891 50 38.6741 50 24.8891C50 24.7666 49.9925 24.6466 49.9825 24.4891C49.965 23.7266 49.6025 23.0116 48.995 22.5491ZM16.25 9.88911C18.32 9.88911 20 11.5666 20 13.6391C20 15.7116 18.32 17.3891 16.25 17.3891C14.18 17.3891 12.5 15.7116 12.5 13.6391C12.5 11.5666 14.18 9.88911 16.25 9.88911ZM11.25 29.8891C9.18 29.8891 7.5 28.2116 7.5 26.1391C7.5 24.0666 9.18 22.3891 11.25 22.3891C13.32 22.3891 15 24.0666 15 26.1391C15 28.2116 13.32 29.8891 11.25 29.8891ZM18.75 39.8891C16.68 39.8891 15 38.2116 15 36.1391C15 34.0666 16.68 32.3891 18.75 32.3891C20.82 32.3891 22.5 34.0666 22.5 36.1391C22.5 38.2116 20.82 39.8891 18.75 39.8891ZM25 23.6391C25 21.5666 26.68 19.8891 28.75 19.8891C30.82 19.8891 32.5 21.5666 32.5 23.6391C32.5 25.7116 30.82 27.3891 28.75 27.3891C26.68 27.3891 25 25.7116 25 23.6391ZM33.75 39.8891C31.68 39.8891 30 38.2116 30 36.1391C30 34.0666 31.68 32.3891 33.75 32.3891C35.82 32.3891 37.5 34.0666 37.5 36.1391C37.5 38.2116 35.82 39.8891 33.75 39.8891Z" />
        </svg>

        <p className={styles.text}>
          {t("cookieBanner.first")}
          {t("cookieBanner.privacyPolicy1")}
          <Link to="/privacy">{t("cookieBanner.privacyPolicyLink")}</Link>
          {t("cookieBanner.privacyPolicy2")}
        </p>
      </div>

      <div className={styles.buttons}>
        <div
          className={`${styles.button} ${
            theme == "light" ? styles.button1Light : styles.button1
          }`}
          onClick={decline}
        >
          {t("cookieBanner.decline")}
        </div>
        <div
          className={`${styles.button} ${
            theme == "light" ? styles.button2Light : styles.button2
          }`}
          onClick={accept}
        >
          {t("cookieBanner.accept")}
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
