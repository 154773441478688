import Footer from "./components/footer";
import "./style/general.css";
import Navigation from "./components/navigation/navigation";
import React, { useEffect, useState, Suspense } from "react";
import {
  Route,
  Routes,
  useLocation,
  BrowserRouter,
  useNavigate,
} from "react-router-dom";
import CookieBanner from "./components/cookieBanner/cookieBanner";
import { getAcceptCookie } from "./utils";
import { MessageContextProvider } from "./context/message";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoadingAnimation from "./assets/logo/loadingAnimation.json";

const Product = React.lazy(() => import("./pages/Product"));
const ProductPay = React.lazy(() => import("./pages/ProductPay"));
const Pay = React.lazy(() => import("./pages/Pay"));

import { ThemeProvider } from "./context/themeContext/themeContext";
import { AuthProvider } from "./context/auth/authContext";
import {
  ThirdwebProvider,
  bloctoWallet,
  coin98Wallet,
  coinbaseWallet,
  coreWallet,
  cryptoDefiWallet,
  frameWallet,
  metamaskWallet,
  okxWallet,
  oneKeyWallet,
  phantomWallet,
  rabbyWallet,
  rainbowWallet,
  safeWallet,
  trustWallet,
  walletConnect,
  xdefiWallet,
  zerionWallet,
} from "@thirdweb-dev/react";
import { useTranslation } from "react-i18next";
import SuspenseWrapper from "./hoc/SuspenseWrapper";
import { Player } from "@lottiefiles/react-lottie-player";
const DashboardLayout = React.lazy(() =>
  import("./containers/dashboardLayout"),
);

const PersonalDashboard = React.lazy(() =>
  import("./containers/personalDashboard"),
);

const ReferralDashboard = React.lazy(() =>
  import("./containers/referralDashboard"),
);

const SalesDashboard = React.lazy(() => import("./containers/salesDashboard"));

const SettingLayout = React.lazy(() => import("./containers/settingLayout"));

const SettingPage = React.lazy(() => import("./containers/settings"));

const Products = React.lazy(() => import("./containers/products"));

const CreateInvoice = React.lazy(() =>
  import("./containers/createInvoiceDashboard"),
);

const AuthLayout = React.lazy(() => import("./containers/authLayout"));

const LoginForm = React.lazy(() => import("./containers/login"));

const SignForm = React.lazy(() => import("./containers/signUp"));

const ForgotPassword = React.lazy(() => import("./containers/forgotPassword"));

const SetPasswordForm = React.lazy(() => import("./containers/setPassword"));

const PageNotFound = React.lazy(() => import("./components/pageNotFound"));

const AddUserForTablet = React.lazy(() =>
  import("./components/addUserForTablet"),
);

const KycDashboard = React.lazy(() => import("./containers/kycDasboard"));

const DownloadApp = React.lazy(() => import("./containers/downloadApp"));

const SettingSideBar = React.lazy(() => import("./components/settingSideBar"));

const UserApplication = React.lazy(() =>
  import("./containers/userApplication"),
);

function App() {
  const { t } = useTranslation();
  useEffect(() => {
    const scrollEvent = () => {
      const scrollElement = document.querySelectorAll(".scroll");
      const slideElement = document.querySelectorAll(".slide-left");
      const slideElement2 = document.querySelectorAll(".slide-right");

      const addClass = (element, className) => {
        for (let i = 0; i < element.length; i++) {
          const sectionTop = element[i].offsetTop;

          const scrollPosition = window.scrollY;

          if (scrollPosition + window.innerHeight * 0.6 >= sectionTop) {
            element[i].classList.add(className);
          }
        }
      };

      addClass(scrollElement, "scrollAnimation");
      addClass(slideElement, "slideAnimation");
      addClass(slideElement2, "slideAnimation");
    };

    window.addEventListener("scroll", scrollEvent);

    document.addEventListener("contextmenu", (event) => {
      // event.preventDefault();
    });

    return () => {
      window.removeEventListener("scroll", () => scrollEvent);
    };
  }, []);

  const [ck, setCK] = useState(getAcceptCookie());

  return (
    <ThirdwebProvider
      activeChain="ethereum"
      clientId={process.env.REACT_APP_THIRDWEB_CLIENT_ID}
      supportedWallets={[
        metamaskWallet(),
        coinbaseWallet({ recommended: true }),
        walletConnect(),
        safeWallet({
          personalWallets: [
            metamaskWallet(),
            coinbaseWallet({ recommended: true }),
            walletConnect(),
            trustWallet(),
            zerionWallet(),
            bloctoWallet(),
            frameWallet(),
            rainbowWallet(),
            phantomWallet(),
          ],
        }),
        trustWallet(),
        zerionWallet(),
        bloctoWallet(),
        frameWallet(),
        rainbowWallet(),
        phantomWallet(),
        okxWallet(),
        coin98Wallet(),
        coreWallet(),
        cryptoDefiWallet(),
        oneKeyWallet(),
        rabbyWallet(),
        xdefiWallet(),
      ]}
    >
      <AuthProvider>
        <BrowserRouter>
          <ThemeProvider>
            <div className={`App`}>
              <MessageContextProvider>
                <ToastContainer hideProgressBar autoClose={true} />
                <ScrollToTop>
                  <SuspenseWrapper>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/login" element={<LoginForm />} />
                      {/* <Route path="/get-started" element={<SignForm />} /> */}
                      <Route
                        path="/get-started"
                        element={<UserApplication />}
                      />
                      <Route
                        path="/forgot-password"
                        element={
                          <AuthLayout>
                            <ForgotPassword />
                          </AuthLayout>
                        }
                      />
                      <Route
                        path="/reset-password"
                        element={
                          <AuthLayout>
                            <SetPasswordForm />
                          </AuthLayout>
                        }
                      />
                      <Route path="/install-app" element={<DownloadApp />} />
                      <Route
                        path="/settings"
                        element={
                          <SettingLayout title={"settingPage.title"}>
                            <SettingPage />
                          </SettingLayout>
                        }
                      />
                      <Route
                        path="/setting-options"
                        element={
                          <SettingLayout title={"settingPage.title"}>
                            <SettingSideBar />
                          </SettingLayout>
                        }
                      />
                      <Route
                        path="/personal-dashboard"
                        element={
                          <DashboardLayout
                            title={"personalDashboard.title"}
                            type={"admin"}
                          >
                            <PersonalDashboard />
                          </DashboardLayout>
                        }
                      />
                      <Route
                        path="/referral-dashboard"
                        element={
                          <DashboardLayout title={"referralDashboard.title"}>
                            <ReferralDashboard />
                          </DashboardLayout>
                        }
                      />
                      <Route
                        path="/sales-dashboard"
                        element={
                          <DashboardLayout title={"salesDashboard.title"}>
                            <SalesDashboard />
                          </DashboardLayout>
                        }
                      />
                      <Route
                        path="/products-dashboard"
                        element={
                          <DashboardLayout title={"productsDashboard.title"}>
                            <Products />
                          </DashboardLayout>
                        }
                      />
                      <Route
                        path="/create-invoice"
                        element={
                          <DashboardLayout title={"payments.createInvoice"}>
                            <CreateInvoice />
                          </DashboardLayout>
                        }
                      />
                      <Route
                        path="/product-detail/:productLink"
                        element={
                          <DashboardLayout
                            title={"productsDashboard.productDetail"}
                          >
                            <Product />
                          </DashboardLayout>
                        }
                      />

                      <Route
                        path="/add-user"
                        element={
                          <DashboardLayout
                            title={"dashboard.modal.titleCreate"}
                          >
                            <AddUserForTablet />
                          </DashboardLayout>
                        }
                      />

                      <Route
                        path="/kyc-dashboard"
                        element={
                          <DashboardLayout title={"kycDashboard.title"}>
                            <KycDashboard />
                          </DashboardLayout>
                        }
                      />

                      <Route
                        path="/product/:productLink"
                        element={<ProductPay />}
                      />
                      <Route
                        path="/product/:productLink/pay"
                        element={
                          <>
                            {/* <Navigation /> */}
                            <ProductPay />
                            {/* <Footer /> */}
                          </>
                        }
                      />
                      <Route
                        path="/pay/:payLink"
                        element={
                          <>
                            {/* <Navigation /> */}
                            <Pay />
                            {/* <Footer /> */}
                          </>
                        }
                      />
                      <Route
                        path="*"
                        element={
                          <>
                            <Navigation />
                            <PageNotFound />
                          </>
                        }
                      />
                    </Routes>
                  </SuspenseWrapper>
                </ScrollToTop>

                {/* COOKIE BANNER */}
                {!ck && <CookieBanner close={() => setCK(true)} />}
              </MessageContextProvider>
            </div>
          </ThemeProvider>
        </BrowserRouter>
      </AuthProvider>
    </ThirdwebProvider>
  );
}

export default App;

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/login", { replace: true });
  }, []);
  return <></>;
};
