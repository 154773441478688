import { ethers } from "ethers";
import CryptoJS from "crypto-js";
import backendAPI from "./api/backendAPI";
import NefentusLogo from "./assets/logo/logo.svg";
import MetaMaskLogo from "./assets/logo/MetaMask.svg";
import WalletConnectLogo from "./assets/logo/WalletConnect.svg";
import Ethereum from "./assets/icon/crypto/ethereum.svg";
import CoinbaseLogo from "./assets/logo/coinbase.svg";
import TrustLogo from "./assets/logo/trust.png";
import NefentusBackground from "./assets/newDashboardIcons/nefentus-background.png";
import MetamaskBackground from "./assets/newDashboardIcons/metamask-background.png";
import WalletConnectBackground from "./assets/newDashboardIcons/walletconnect2-background.png";
import CoinbaseBackground from "./assets/newDashboardIcons/coinbase-background.png";
import TrustBackground from "./assets/newDashboardIcons/trust-background.png";
import PhantomBackground from "./assets/newDashboardIcons/phantom-background.png";
import ZerionBackground from "./assets/newDashboardIcons/zerion-background.png";
import BloctoBackground from "./assets/newDashboardIcons/blocto-background.png";
import RainbowBackground from "./assets/newDashboardIcons/rainbow-background.png";
import OnekeyBackground from "./assets/newDashboardIcons/onekey-background.png";
import RabbyBackground from "./assets/newDashboardIcons/rabby-background.png";
import XDefiBackground from "./assets/newDashboardIcons/xdefi-background.png";

import NefentusLightBackground from "./assets/newDashboardIcons/light-theme/nefentus-background-light.png";
import MetamaskLightBackground from "./assets/newDashboardIcons/light-theme/metamask-background-light.png";
import WalletConnectLightBackground from "./assets/newDashboardIcons/light-theme/walletconnect-background-light.png";
import CoinbaseLightBackground from "./assets/newDashboardIcons/light-theme/coinbase-background-light.png";
import TrustLightBackground from "./assets/newDashboardIcons/light-theme/trust-background-light.png";
import PhantomLightBackground from "./assets/newDashboardIcons/light-theme/phantom-background-light.png";
import ZerionLightBackground from "./assets/newDashboardIcons/light-theme/zerion-background-light.png";
import BloctoLightBackground from "./assets/newDashboardIcons/light-theme/blocto-background-light.png";
import RainbowLightBackground from "./assets/newDashboardIcons/light-theme/rainbow-background-light.png";
import OnekeyLightBackground from "./assets/newDashboardIcons/light-theme/onekey-background-light.png";
import RabbyLightBackground from "./assets/newDashboardIcons/light-theme/rabby-background-light.png";
import XDefiLightBackground from "./assets/newDashboardIcons/light-theme/xdefi-background-light.png";

import Safe from "./assets/logo/safe.png";
import Blocto from "./assets/logo/blocto.png";
import Frame from "./assets/logo/frame.png";
import Rainbow from "./assets/logo/rainbow.png";
import Phantom from "./assets/logo/phantom.png";
import Coin98 from "./assets/logo/coin98.png";
import CoreWallet from "./assets/logo/corewallet.png";
import CryptoDefi from "./assets/logo/cryptodefi.png";
import Okx from "./assets/logo/okx.png";
import OneKey from "./assets/logo/onekey.png";
import Rabby from "./assets/logo/rabby.png";
import XDefi from "./assets/logo/xdefi.png";
import Cookies from "js-cookie";

export function formatTokenBalance(x, round = 2) {
  const parsedFloat = parseFloat(x);
  if (isNaN(parsedFloat)) {
    return "0.0";
  } else {
    let roundedNumber = parsedFloat.toFixed(
      !isNaN(round) && parseInt(round) ? parseInt(round) : 2,
    );

    // Split the number into integer and decimal parts
    let parts = roundedNumber.split(".");
    let integerPart = parts[0];
    let decimalPart = parts[1] || "";

    // Add commas to the integer part using a simple regex
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Reassemble the number
    return decimalPart.length > 0
      ? `${integerPart}.${decimalPart}`
      : integerPart;
  }
}

export function formatUSDBalance(x) {
  // Round the number to 2 decimal places
  return formatTokenBalance(x, 2);
}

export const formatWalletAddress = (address, symbolCount = 4) => {
  isNaN(symbolCount) && (symbolCount = 4);
  if (!address || address.length <= symbolCount * 2 + 2) {
    return address;
  }

  const start = address.substring(0, symbolCount + 2);
  const end = address.substring(address.length - symbolCount);
  return `${start}....${end}`;
};

export function nullToZeroAddress(address) {
  if (address === null) return ethers.constants.AddressZero;
  return address;
}

export function zeroAddressToNull(address) {
  if (address === ethers.constants.AddressZero) return null;
  return address;
}

export function toChecksumAddress(address) {
  if (address === null) return null;
  return ethers.utils.getAddress(address);
}

export function getRole(user) {
  // const roles = "ROLE_ADMIN";
  const roles = user?.roles || [];
  // const roleArray = roles?.split(",");
  const isVendor = roles?.includes("ROLE_VENDOR");
  const isBroker = roles?.includes("ROLE_BROKER");
  const isSeniorBroker = roles?.includes("ROLE_SENIOR_BROKER");
  const isLeader = roles?.includes("ROLE_LEADER");
  const isAdmin = roles?.includes("ROLE_ADMIN");
  const isPrivate = roles?.includes("ROLE_PRIVATE");

  if (isAdmin) {
    return "admin";
  } else if (isVendor) {
    return "vendor";
  } else if (isBroker) {
    return "broker";
  } else if (isSeniorBroker) {
    return "seniorbroker";
  } else if (isLeader) {
    return "leader";
  } else if (isPrivate) {
    return "private";
  } else return "";
}

export function dashboardLink(user) {
  let role = getRole(user);
  if (
    role === "affiliate" ||
    role === "broker" ||
    role === "seniorbroker" ||
    role === "leader"
  ) {
    role = "partner";
  } else if (role === "admin") {
    role = "admin";
  } else if (role === "vendor") {
    return "/dashboard";
  } else if (role === undefined) {
    return "/login";
  }
  return "/dashboard/" + role;
}

export const encryptData = (password) => {
  try {
    return CryptoJS.AES.encrypt(
      JSON.stringify(password),
      process.env.VITE_REACT_APP_SECRET_WORD,
    ).toString();
  } catch (error) {
    console.error("Encryption failed. Please check your input.");
  }
};

export const decryptData = (password) => {
  try {
    const bytes = CryptoJS.AES.decrypt(
      password,
      process.env.VITE_REACT_APP_SECRET_WORD,
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed. Please check your input.");
  }
};

export const reformatFooterInfo = (pages, links) => {
  let result = [];

  for (let i = 0; i < pages.length; i++) {
    result[i] = { text: pages[i], link: links[i] };
  }
  return result;
};

export const validateEmail = (email) => {
  const emailRegex = /.+@[^@]+\.[^@]{1,}$/;
  return emailRegex.test(email);
};

export const validatePhoneNumber = (number) => {
  const phoneRegex = /[\+\-\/\\\(\)0-9]*/;
  return phoneRegex.test(number);
};

export const formatIncome = (income) => {
  if (Number.isInteger(income)) return income;
  else return income.toFixed(2);
};

export const getWalletIcon = (type) => {
  switch (type?.toLowerCase()) {
    case "metamask":
      return MetaMaskLogo;
    case "walletconnect":
      return WalletConnectLogo;
    case "coinbase":
      return CoinbaseLogo;
    case "trust":
      return TrustLogo;
    case "safe":
      return Safe;
    case "blocto":
      return Blocto;
    case "frame":
      return Frame;
    case "rainbowwallet":
      return Rainbow;
    case "phantom":
      return Phantom;
    case "coin98":
      return Coin98;
    case "corewallet":
      return CoreWallet;
    case "cryptodefi":
      return CryptoDefi;
    case "okx":
      return Okx;
    case "onekey":
      return OneKey;
    case "rabby":
      return Rabby;
    case "xdefi":
      return XDefi;
    case "internal":
      return NefentusLogo;
    default:
      return Ethereum;
  }
};

export const getWalleBackground = (type, theme) => {
  switch (type?.toLowerCase()) {
    case "metamask":
      return {
        background:
          theme == "light" ? MetamaskLightBackground : MetamaskBackground,
        logo: MetaMaskLogo,
      };
    case "walletconnect":
      return {
        background:
          theme == "light"
            ? WalletConnectLightBackground
            : WalletConnectBackground,
        logo: WalletConnectLogo,
      };
    case "coinbase":
      return {
        background:
          theme == "light" ? CoinbaseLightBackground : CoinbaseBackground,
        logo: CoinbaseLogo,
      };
    case "trust":
      return {
        background: theme == "light" ? TrustLightBackground : TrustBackground,
        logo: TrustLogo,
      };
    case "blocto":
      return {
        background: theme == "light" ? BloctoLightBackground : BloctoBackground,
        logo: Blocto,
      };
    case "rainbowwallet":
      return {
        background:
          theme == "light" ? RainbowLightBackground : RainbowBackground,
        logo: Rainbow,
      };
    case "phantom":
      return {
        background:
          theme == "light" ? PhantomLightBackground : PhantomBackground,
        logo: Phantom,
      };
    case "onekey":
      return {
        background: theme == "light" ? OnekeyLightBackground : OnekeyBackground,
        logo: OneKey,
      };
    case "rabby":
      return {
        background: theme == "light" ? RabbyLightBackground : RabbyBackground,
        logo: Rabby,
      };
    case "xdefi":
      return {
        background: theme == "light" ? XDefiLightBackground : XDefiBackground,
        logo: XDefi,
      };
    case "zerion":
      return {
        background: theme == "light" ? ZerionLightBackground : ZerionBackground,
        logo: NefentusLogo,
      };
    default:
      return {
        background:
          theme == "light" ? NefentusLightBackground : NefentusBackground,
        logo: NefentusLogo,
      };
  }
};

export const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
};

export const isNumber = (value) => {
  return typeof value === "number" && isFinite(value);
};

export const graphDataToList = (data) => {
  const dataList = Object.entries(data).map(([key, value]) => ({
    label: key,
    amount: value,
  }));
  // Sort it
  dataList.sort((a, b) => {
    const dateA = new Date(a.label);
    const dateB = new Date(b.label);
    return dateA - dateB;
  });
  return dataList;
};

export const isWalletConflict = () => {
  const isCoinbase =
    window.ethereum?.isCoinbaseWallet ||
    window.ethereum?.providers?.find((x) => x.isCoinbaseWallet) ||
    window.web3?.currentProvider?.isCoinbaseWallet;
  const isMetamask =
    window.ethereum?.isMetaMask ||
    window.ethereum?.providers?.find((x) => x.isMetaMask) ||
    window.web3?.currentProvider?.isMetaMask;
  const isPhantom = window.ethereum?.isPhantom;
  return (
    window.ethereum?.overrideIsMetaMask ||
    (isCoinbase && isMetamask) ||
    (isPhantom && isMetamask)
  );
};

export const setCookie = (name, value, exp = 30) => {
  Cookies.set(name, value, {
    expires: exp,
    secure: false,
    sameSite: "strict",
    path: "/",
  });
};

export const acceptCookie = () => {
  setCookie("acceptCookie", true);
};

export const declineCookie = () => {
  setCookie("acceptCookie", false);
};

export const getAcceptCookie = () => {
  return Cookies.get("acceptCookie");
};

export const getThemeCookie = () => {
  return Cookies.get("theme");
};

function assertString(input) {
  const isString = typeof input === "string" || input instanceof String;

  if (!isString) {
    let invalidType = typeof input;
    if (input === null) invalidType = "null";
    else if (invalidType === "object") invalidType = input.constructor.name;

    throw new TypeError(`Expected a string but received a ${invalidType}`);
  }
}

const phones = {
  "am-AM": /^(\+?374|0)((10|[9|7][0-9])\d{6}$|[2-4]\d{7}$)/,
  "ar-AE": /^((\+?971)|0)?5[024568]\d{7}$/,
  "ar-BH": /^(\+?973)?(3|6)\d{7}$/,
  "ar-DZ": /^(\+?213|0)(5|6|7)\d{8}$/,
  "ar-LB": /^(\+?961)?((3|81)\d{6}|7\d{7})$/,
  "ar-EG": /^((\+?20)|0)?1[0125]\d{8}$/,
  "ar-IQ": /^(\+?964|0)?7[0-9]\d{8}$/,
  "ar-JO": /^(\+?962|0)?7[789]\d{7}$/,
  "ar-KW": /^(\+?965)([569]\d{7}|41\d{6})$/,
  "ar-LY": /^((\+?218)|0)?(9[1-6]\d{7}|[1-8]\d{7,9})$/,
  "ar-MA": /^(?:(?:\+|00)212|0)[5-7]\d{8}$/,
  "ar-OM": /^((\+|00)968)?(9[1-9])\d{6}$/,
  "ar-PS": /^(\+?970|0)5[6|9](\d{7})$/,
  "ar-SA": /^(!?(\+?966)|0)?5\d{8}$/,
  "ar-SD": /^((\+?249)|0)?(9[012369]|1[012])\d{7}$/,
  "ar-SY": /^(!?(\+?963)|0)?9\d{8}$/,
  "ar-TN": /^(\+?216)?[2459]\d{7}$/,
  "az-AZ": /^(\+994|0)(10|5[015]|7[07]|99)\d{7}$/,
  "bs-BA": /^((((\+|00)3876)|06))((([0-3]|[5-6])\d{6})|(4\d{7}))$/,
  "be-BY": /^(\+?375)?(24|25|29|33|44)\d{7}$/,
  "bg-BG": /^(\+?359|0)?8[789]\d{7}$/,
  "bn-BD": /^(\+?880|0)1[13456789][0-9]{8}$/,
  "ca-AD": /^(\+376)?[346]\d{5}$/,
  "cs-CZ": /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
  "da-DK": /^(\+?45)?\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/,
  "de-DE": /^((\+49|0)1)(5[0-25-9]\d|6([23]|0\d?)|7([0-57-9]|6\d))\d{7,9}$/,
  "de-AT": /^(\+43|0)\d{1,4}\d{3,12}$/,
  "de-CH": /^(\+41|0)([1-9])\d{1,9}$/,
  "de-LU": /^(\+352)?((6\d1)\d{6})$/,
  "dv-MV": /^(\+?960)?(7[2-9]|9[1-9])\d{5}$/,
  "el-GR": /^(\+?30|0)?6(8[5-9]|9(?![26])[0-9])\d{7}$/,
  "el-CY": /^(\+?357?)?(9(9|6)\d{6})$/,
  "en-AI":
    /^(\+?1|0)264(?:2(35|92)|4(?:6[1-2]|76|97)|5(?:3[6-9]|8[1-4])|7(?:2(4|9)|72))\d{4}$/,
  "en-AU": /^(\+?61|0)4\d{8}$/,
  "en-AG": /^(?:\+1|1)268(?:464|7(?:1[3-9]|[28]\d|3[0246]|64|7[0-689]))\d{4}$/,
  "en-BM": /^(\+?1)?441(((3|7)\d{6}$)|(5[0-3][0-9]\d{4}$)|(59\d{5}$))/,
  "en-BS": /^(\+?1[-\s]?|0)?\(?242\)?[-\s]?\d{3}[-\s]?\d{4}$/,
  "en-GB": /^(\+?44|0)7\d{9}$/,
  "en-GG": /^(\+?44|0)1481\d{6}$/,
  "en-GH": /^(\+233|0)(20|50|24|54|27|57|26|56|23|28|55|59)\d{7}$/,
  "en-GY": /^(\+592|0)6\d{6}$/,
  "en-HK": /^(\+?852[-\s]?)?[456789]\d{3}[-\s]?\d{4}$/,
  "en-MO": /^(\+?853[-\s]?)?[6]\d{3}[-\s]?\d{4}$/,
  "en-IE": /^(\+?353|0)8[356789]\d{7}$/,
  "en-IN": /^(\+?91|0)?[6789]\d{9}$/,
  "en-JM": /^(\+?876)?\d{7}$/,
  "en-KE": /^(\+?254|0)(7|1)\d{8}$/,
  "fr-CF": /^(\+?236| ?)(70|75|77|72|21|22)\d{6}$/,
  "en-SS": /^(\+?211|0)(9[1257])\d{7}$/,
  "en-KI": /^((\+686|686)?)?( )?((6|7)(2|3|8)[0-9]{6})$/,
  "en-KN": /^(?:\+1|1)869(?:46\d|48[89]|55[6-8]|66\d|76[02-7])\d{4}$/,
  "en-LS": /^(\+?266)(22|28|57|58|59|27|52)\d{6}$/,
  "en-MT": /^(\+?356|0)?(99|79|77|21|27|22|25)[0-9]{6}$/,
  "en-MU": /^(\+?230|0)?\d{8}$/,
  "en-MW":
    /^(\+?265|0)(((77|88|31|99|98|21)\d{7})|(((111)|1)\d{6})|(32000\d{4}))$/,
  "en-NA": /^(\+?264|0)(6|8)\d{7}$/,
  "en-NG": /^(\+?234|0)?[789]\d{9}$/,
  "en-NZ": /^(\+?64|0)[28]\d{7,9}$/,
  "en-PG": /^(\+?675|0)?(7\d|8[18])\d{6}$/,
  "en-PK": /^((00|\+)?92|0)3[0-6]\d{8}$/,
  "en-PH": /^(09|\+639)\d{9}$/,
  "en-RW": /^(\+?250|0)?[7]\d{8}$/,
  "en-SG": /^(\+65)?[3689]\d{7}$/,
  "en-SL": /^(\+?232|0)\d{8}$/,
  "en-TZ": /^(\+?255|0)?[67]\d{8}$/,
  "en-UG": /^(\+?256|0)?[7]\d{8}$/,
  "en-US":
    /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
  "en-ZA": /^(\+?27|0)\d{9}$/,
  "en-ZM": /^(\+?26)?09[567]\d{7}$/,
  "en-ZW": /^(\+263)[0-9]{9}$/,
  "en-BW": /^(\+?267)?(7[1-8]{1})\d{6}$/,
  "es-AR": /^\+?549(11|[2368]\d)\d{8}$/,
  "es-BO": /^(\+?591)?(6|7)\d{7}$/,
  "es-CO": /^(\+?57)?3(0(0|1|2|4|5)|1\d|2[0-4]|5(0|1))\d{7}$/,
  "es-CL": /^(\+?56|0)[2-9]\d{1}\d{7}$/,
  "es-CR": /^(\+506)?[2-8]\d{7}$/,
  "es-CU": /^(\+53|0053)?5\d{7}$/,
  "es-DO": /^(\+?1)?8[024]9\d{7}$/,
  "es-HN": /^(\+?504)?[9|8|3|2]\d{7}$/,
  "es-EC": /^(\+?593|0)([2-7]|9[2-9])\d{7}$/,
  "es-ES": /^(\+?34)?[6|7]\d{8}$/,
  "es-PE": /^(\+?51)?9\d{8}$/,
  "es-MX": /^(\+?52)?(1|01)?\d{10,11}$/,
  "es-NI": /^(\+?505)\d{7,8}$/,
  "es-PA": /^(\+?507)\d{7,8}$/,
  "es-PY": /^(\+?595|0)9[9876]\d{7}$/,
  "es-SV": /^(\+?503)?[67]\d{7}$/,
  "es-UY": /^(\+598|0)9[1-9][\d]{6}$/,
  "es-VE": /^(\+?58)?(2|4)\d{9}$/,
  "et-EE": /^(\+?372)?\s?(5|8[1-4])\s?([0-9]\s?){6,7}$/,
  "fa-IR": /^(\+?98[\-\s]?|0)9[0-39]\d[\-\s]?\d{3}[\-\s]?\d{4}$/,
  "fi-FI": /^(\+?358|0)\s?(4[0-6]|50)\s?(\d\s?){4,8}$/,
  "fj-FJ": /^(\+?679)?\s?\d{3}\s?\d{4}$/,
  "fo-FO": /^(\+?298)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
  "fr-BF": /^(\+226|0)[67]\d{7}$/,
  "fr-BJ": /^(\+229)\d{8}$/,
  "fr-CD": /^(\+?243|0)?(8|9)\d{8}$/,
  "fr-CM": /^(\+?237)6[0-9]{8}$/,
  "fr-FR": /^(\+?33|0)[67]\d{8}$/,
  "fr-GF": /^(\+?594|0|00594)[67]\d{8}$/,
  "fr-GP": /^(\+?590|0|00590)[67]\d{8}$/,
  "fr-MQ": /^(\+?596|0|00596)[67]\d{8}$/,
  "fr-PF": /^(\+?689)?8[789]\d{6}$/,
  "fr-RE": /^(\+?262|0|00262)[67]\d{8}$/,
  "fr-WF": /^(\+681)?\d{6}$/,
  "he-IL": /^(\+972|0)([23489]|5[012345689]|77)[1-9]\d{6}$/,
  "hu-HU": /^(\+?36|06)(20|30|31|50|70)\d{7}$/,
  "id-ID":
    /^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/,
  "ir-IR": /^(\+98|0)?9\d{9}$/,
  "it-IT": /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
  "it-SM": /^((\+378)|(0549)|(\+390549)|(\+3780549))?6\d{5,9}$/,
  "ja-JP": /^(\+81[ \-]?(\(0\))?|0)[6789]0[ \-]?\d{4}[ \-]?\d{4}$/,
  "ka-GE": /^(\+?995)?(79\d{7}|5\d{8})$/,
  "kk-KZ": /^(\+?7|8)?7\d{9}$/,
  "kl-GL": /^(\+?299)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
  "ko-KR": /^((\+?82)[ \-]?)?0?1([0|1|6|7|8|9]{1})[ \-]?\d{3,4}[ \-]?\d{4}$/,
  "ky-KG": /^(\+?7\s?\+?7|0)\s?\d{2}\s?\d{3}\s?\d{4}$/,
  "lt-LT": /^(\+370|8)\d{8}$/,
  "lv-LV": /^(\+?371)2\d{7}$/,
  "mg-MG": /^((\+?261|0)(2|3)\d)?\d{7}$/,
  "mn-MN": /^(\+|00|011)?976(77|81|88|91|94|95|96|99)\d{6}$/,
  "my-MM": /^(\+?959|09|9)(2[5-7]|3[1-2]|4[0-5]|6[6-9]|7[5-9]|9[6-9])[0-9]{7}$/,
  "ms-MY": /^(\+?60|0)1(([0145](-|\s)?\d{7,8})|([236-9](-|\s)?\d{7}))$/,
  "mz-MZ": /^(\+?258)?8[234567]\d{7}$/,
  "nb-NO": /^(\+?47)?[49]\d{7}$/,
  "ne-NP": /^(\+?977)?9[78]\d{8}$/,
  "nl-BE": /^(\+?32|0)4\d{8}$/,
  "nl-NL": /^(((\+|00)?31\(0\))|((\+|00)?31)|0)6{1}\d{8}$/,
  "nl-AW": /^(\+)?297(56|59|64|73|74|99)\d{5}$/,
  "nn-NO": /^(\+?47)?[49]\d{7}$/,
  "pl-PL": /^(\+?48)? ?([5-8]\d|45) ?\d{3} ?\d{2} ?\d{2}$/,
  "pt-BR":
    /^((\+?55\ ?[1-9]{2}\ ?)|(\+?55\ ?\([1-9]{2}\)\ ?)|(0[1-9]{2}\ ?)|(\([1-9]{2}\)\ ?)|([1-9]{2}\ ?))((\d{4}\-?\d{4})|(9[1-9]{1}\d{3}\-?\d{4}))$/,
  "pt-PT": /^(\+?351)?9[1236]\d{7}$/,
  "pt-AO": /^(\+244)\d{9}$/,
  "ro-MD": /^(\+?373|0)((6(0|1|2|6|7|8|9))|(7(6|7|8|9)))\d{6}$/,
  "ro-RO": /^(\+?40|0)\s?7\d{2}(\/|\s|\.|-)?\d{3}(\s|\.|-)?\d{3}$/,
  "ru-RU": /^(\+?7|8)?9\d{9}$/,
  "si-LK": /^(?:0|94|\+94)?(7(0|1|2|4|5|6|7|8)( |-)?)\d{7}$/,
  "sl-SI":
    /^(\+386\s?|0)(\d{1}\s?\d{3}\s?\d{2}\s?\d{2}|\d{2}\s?\d{3}\s?\d{3})$/,
  "sk-SK": /^(\+?421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
  "so-SO": /^(\+?252|0)((6[0-9])\d{7}|(7[1-9])\d{7})$/,
  "sq-AL": /^(\+355|0)6[789]\d{6}$/,
  "sr-RS": /^(\+3816|06)[- \d]{5,9}$/,
  "sv-SE": /^(\+?46|0)[\s\-]?7[\s\-]?[02369]([\s\-]?\d){7}$/,
  "tg-TJ": /^(\+?992)?[5][5]\d{7}$/,
  "th-TH": /^(\+66|66|0)\d{9}$/,
  "tr-TR": /^(\+?90|0)?5\d{9}$/,
  "tk-TM": /^(\+993|993|8)\d{8}$/,
  "uk-UA": /^(\+?38|8)?0\d{9}$/,
  "uz-UZ": /^(\+?998)?(6[125-79]|7[1-69]|88|9\d)\d{7}$/,
  "vi-VN":
    /^((\+?84)|0)((3([2-9]))|(5([25689]))|(7([0|6-9]))|(8([1-9]))|(9([0-9])))([0-9]{7})$/,
  "zh-CN": /^((\+|00)86)?(1[3-9]|9[28])\d{9}$/,
  "zh-TW": /^(\+?886\-?|0)?9\d{8}$/,
  "dz-BT": /^(\+?975|0)?(17|16|77|02)\d{6}$/,
  "ar-YE": /^(((\+|00)9677|0?7)[0137]\d{7}|((\+|00)967|0)[1-7]\d{6})$/,
  "ar-EH": /^(\+?212|0)[\s\-]?(5288|5289)[\s\-]?\d{5}$/,
  "fa-AF": /^(\+93|0)?(2{1}[0-8]{1}|[3-5]{1}[0-4]{1})(\d{7})$/,
};
/* eslint-enable max-len */

// aliases
phones["en-CA"] = phones["en-US"];
phones["fr-CA"] = phones["en-CA"];
phones["fr-BE"] = phones["nl-BE"];
phones["zh-HK"] = phones["en-HK"];
phones["zh-MO"] = phones["en-MO"];
phones["ga-IE"] = phones["en-IE"];
phones["fr-CH"] = phones["de-CH"];
phones["it-CH"] = phones["fr-CH"];

export default function isMobilePhone(str, locale, options) {
  assertString(str);
  if (options && options.strictMode && !str.startsWith("+")) {
    return false;
  }
  if (Array.isArray(locale)) {
    return locale.some((key) => {
      // https://github.com/gotwarlost/istanbul/blob/master/ignoring-code-for-coverage.md#ignoring-code-for-coverage-purposes
      // istanbul ignore else
      if (phones.hasOwnProperty(key)) {
        const phone = phones[key];
        if (phone.test(str)) {
          return true;
        }
      }
      return false;
    });
  } else if (locale in phones) {
    return phones[locale].test(str);
    // alias falsey locale as 'any'
  } else if (!locale || locale === "any") {
    for (const key in phones) {
      // istanbul ignore else
      if (phones.hasOwnProperty(key)) {
        const phone = phones[key];
        if (phone.test(str)) {
          return true;
        }
      }
    }
    return false;
  }
  throw new Error(`Invalid locale '${locale}'`);
}

export const locales = Object.keys(phones);

export const separateText = (string) => {
  let list = string.split("\n");

  let renderList = [];

  for (let i = 0; i < list.length; i++) {
    renderList.push(list[i]);

    if (i < list.length - 1) {
      renderList.push(<br />);
    }
  }

  return renderList;
};

export const FormatWalletName = (wallet = "Nefentus") => {
  return wallet.substring(0, 1).toUpperCase() + wallet.substring(1);
};

export const labelRender = (props) => {
  const { label, value } = props;
  if (label) {
    return value;
  }
  return <span></span>;
};

export const sanitizeEmail = (email) => email?.toLowerCase()?.trim();
