import backendAPI from "./api/backendAPI";
import { encryptData, setCookie } from "./utils";
import Cookies from "js-cookie";

export const login = async (
  checkBox,
  email,
  password,
  onFailed,
  onOtp,
  onSuccess,
) => {
  if (checkBox && Cookies.get("acceptCookie")) {
    setCookie("nefentus-username", email, 365);
    setCookie("nefentus-password", encryptData(password), 365);
    setCookie("nefentus-remember-me", checkBox, 365);
  } else {
    setCookie("nefentus-username", "", 365);
    setCookie("nefentus-password", "", 365);
    setCookie("nefentus-remember-me", false, 365);
  }
  const response = await new backendAPI().login(email, password, checkBox);
  if (response == null) {
    onFailed();
  } else if (response.hasOtp || response.hasTotp) {
    onOtp(response);
  } else {
    onSuccess(response);
  }
};

export const verifyOtp = async (email, checkbox, code, onFailed, onSuccess) => {
  const response = await new backendAPI().verifyOTP(
    email,
    code,
    checkbox && Cookies.get("acceptCookie") == true,
  );
  if (response == null) {
    onFailed();
  } else {
    onSuccess(response);
  }
};

export const verifyTotp = async (
  email,
  checkbox,
  code,
  onFailed,
  onSuccess,
) => {
  const response = await new backendAPI().verifyTotpToken(
    email,
    code,
    checkbox && Cookies.get("acceptCookie") == true,
  );
  if (response == null) {
    onFailed();
  } else {
    onSuccess(response);
  }
};

export const onNavigateToForgot = async (setUser) => {
  setUser && setUser({});
  await new backendAPI().signout();
  setTimeout(() => {
    window.location.href = "/forgot-password";
  }, 200);
};

export const logOut = async (setUser) => {
  await new backendAPI().signout();
  setTimeout(() => {
    setUser && setUser({});
    window.location.href = "/login";
  }, 200);
};
