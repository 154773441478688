import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "./languages/i18n";

import * as Sentry from "@sentry/react";

// Check if the application is running through VITE_REACT_APP_ENVIRONMENT and if the browser supports service workers.
if (
  (process.env.VITE_REACT_APP_ENVIRONMENT === "development" ||
    process.env.VITE_REACT_APP_ENVIRONMENT === "staging" ||
    process.env.VITE_REACT_APP_ENVIRONMENT === "production") && // Ensure the environment is set to development
  "serviceWorker" in navigator // Verify that the browser supports service workers
) {
  // Add an event listener to the window's load event
  window.addEventListener("load", () => {
    // Register the service worker using the specified URL and options
    navigator.serviceWorker
      .register(
        `${process.env.VITE_REACT_APP_PUBLIC_URL}/service-worker.js`, // Path to the service worker file
        { type: "module" }, // Specifying that the service worker is a module
      )
      .then((registration) => {
        // Logging the service worker registration scope upon successful registration
        console.log(
          "Service Worker registered with scope:",
          registration.scope,
        );
      })
      .catch((error) => {
        // Log an error message if the service worker registration fails
        console.error("Service Worker registration failed:", error);
      });
  });
}

Sentry.init({
  dsn: process.env.VITE_REACT_APP_SENTRY_DSN,
  environment: process.env.VITE_REACT_APP_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/nefentus\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
